<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('pages.add-menu') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <MenuForm :menu="{}" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import MenuForm from "@/components/admin/page/menu/MenuForm.vue"
export default {
  name: 'AddMenu',
  components: { NavBar, MenuForm },
  data: function() {
    return {
      menu: {}
    }
  },
  created: async function() {
  }
}
</script>
<style></style>
