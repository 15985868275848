<template>
  <div class="p-1">
    <b-form @submit="onSubmit" autocomplete="off">
      <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('pages.menu-name')" label-for="name">
        <b-form-input id="name" v-model="menu.name" :placeholder="$t('pages.enter-menu-name')"></b-form-input>
      </b-form-group>
      <b-form-group label-cols-sm="12" label-cols-md="4" :label="$t('pages.select-pages')">
           <!-- prop `add-on-change` is needed to enable adding tags vie the `change` event -->
           <b-form-tags v-model="pagesSelected" add-on-change no-outer-focus size="lg">
             <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
               <b-form-select
                 v-bind="inputAttrs"
                 v-on="inputHandlers"
                 :disabled="disabled || availableOptions.length === 0"
                 :options="availableOptions" class="mb-3">
                 <template #first>
                   <option disabled value="">{{$t('pages.select-pages')}} ...</option>
                 </template>
               </b-form-select>
               <ul v-if="tags.length > 0" class="list-inline d-inline-block">
                 <li v-for="(tag, index) in tags" :key="tag" class="mb-2">
                   <span class="d-inline-block" style="width: 1.4rem;">{{ index + 1 }}.</span> <b-form-tag @remove="removeTag(tag)" :disabled="disabled" size="lg" pill variant="info">{{ tag }}</b-form-tag>
                 </li>
               </ul>
             </template>
           </b-form-tags>
         </b-form-group>
      <hr />
      <b-form-group label-cols-sm="12" label-cols-md="4" >
        <b-overlay :show="saveSpinner" spinner-small spinner-variant="primary-outline" opacity="0.4" class="d-inline-block">
          <b-button type="submit" variant="primary">{{ $t('pages.save-menu')}}</b-button>
        </b-overlay>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { BAvatar, BFormSelect, BFormTags, BFormTag } from 'bootstrap-vue'
export default {
  name: 'MenuForm',
  components:  { BFormSelect, BFormTags, BFormTag },
  props: {
    menu: Object
  },
  data: function() {
    return {
      languageShortName: this.$route.params.languageShortName,
      user: {},
      pagesOptions:[],
      pagesSelected: [],
      pages: [],
      lang: {},
      languagesOverlay: true,
      showOverlay: true,
      saveSpinner: false
    }
  },
  computed: {
    availableOptions() {
      return this.pagesOptions.filter(opt => this.pagesSelected.indexOf(opt) === -1)
    }
  },
  created: async function() {
    this.user = this.$root.user;
    this.showOverlay = true;
    await this.$http.get("/cms/api/page/published/" + this.languageShortName, { headers: {Authorization: this.getAuthData().accessToken }})
      .then(r => {
        this.pages = r.data;
        for (var i = 0; i < r.data.length; i++) {
          const p = r.data[i];
          this.pagesOptions.push(p.title);
        }
      }).catch(e => {
        var message = this.handleError(e);
    });

    await this.$http.get("cms/api/languages/get/" + this.$route.params.languageShortName, { headers: {Authorization: this.getAuthData().accessToken } })
    .then(r => {
      this.lang = r.data;
    }).catch(e => {
      var message = this.handleError(e);
    })

    if (this.menu.pages) {
      for (var i = 0; i < this.menu.pages.length; i++) {
        var p = this.menu.pages[i];
        this.pagesSelected.push(p.title);
      }
    }

    this.showOverlay = false;
  },
  methods: {
    addMenuItem: function() {
      //console.log(this.myPages);
      this.myPages.push({ pages: {id : 0, title: "" } });
      //console.log(this.myPages);
    },

    onSubmit: function() {
      event.preventDefault();
      this.saveSpinner = true;
      //
      var menuPages = [];
      // console.log(this.pages);
      // console.log(this.pagesSelected)
      for (var i = 0; i < this.pagesSelected.length; i++) {
        menuPages.push(this.getPageForTitle(this.pages, this.pagesSelected[i]));
      }
      // console.log(menuPages);
      this.menu.pages = menuPages;
      this.menu.language = this.lang;
      console.log(this.menu)
      this.$http.post('/cms/api/page/menu/save/', this.menu, { headers: {Authorization: this.getAuthData().accessToken }})
        .then(r => {
          this.saveSpinner = false;
          this.$router.push({name : 'MenuForLanguage', params: {languageShortName : this.lang.shortName}});
        })
        .catch(e => {
          this.saveSpinner = false;
          var message = this.handleError(e);
        })



    },

    getPageForTitle: function(pages, title) {
      if (pages) {
        for (var i = 0; i < pages.length; i++) {
          var p = pages[i];
          if (p.title === title) {
            return p
          }
        }
      }
      return null;
    }
  }
}
</script>
<style></style>
